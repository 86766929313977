import React, { Component } from 'react';

export default class Content extends Component {
    constructor(props) {
        super();
    }

    render = () => {
        return (
            <div>
                That word doesnt belong in our registry G
            </div>
        );
    }
}