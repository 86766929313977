import React, { Component } from 'react';
import Word from './Word';
import Result from './Result';

export default class Content extends Component {
    constructor(props) {
        super();
    }

    render = () => {
        return (
            <div className="Content">
                {this.props.isResult ? <Word showResult={this.props.showResult} data={this.props.data.data}/> : <Result showResult={this.props.showResult} data={this.props.data}/> }
            </div>
        );
    }
}