import React, { Component } from 'react';

export default class ResultItem extends Component {
    constructor(props) {
        super();
    }

    showResult = () => {
        this.props.showResult(this.props.data.id);
    }

    render = () => {
        return <div className="Result-item" onClick={this.showResult}> {this.props.data.data.name.join(" / ")} </div>
    }
} 