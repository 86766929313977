import React, { Component } from 'react';
import {FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon} from 'react-share';

export default class Share extends Component {
    constructor(props) {
        super();
    }

    render = () => {
        var location = window.location;
        var shareUrl = location.protocol + '//' + location.host + '/word/';
        shareUrl = shareUrl + this.props.name;
        var title = this.props.name + ' definition'
        return (
            <div className="Share-buttons">
                <FacebookShareButton
                    url={shareUrl}
                    quote={title}
                    className="share-button">
                    <FacebookIcon
                    size={32}
                    round />
                </FacebookShareButton>
                <TwitterShareButton
                    url={shareUrl}
                    title={title}
                    className="share-button">
                    <TwitterIcon
                    size={32}
                    round />
                </TwitterShareButton>
            </div>
        );
    }
}