module.exports = [
    "",
    "Noun",
    "Pronoun",
    "Adjective",
    "Verb",
    "Adverb",
    "Preposition",
    "Conjunction",
    "Interjection"
]