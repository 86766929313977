import React, { Component } from 'react';

import './About.css';

export default class About extends Component {

    render = () => {
        return (
            <div className="About">
                <div className="text-center">
                    <h1 className="mb-4">- Our Mission -</h1>
                    <p>Kaapse dictionary is a registry of slang used in Cape Town (South Africa). It exists purely just because.</p>
                    <h1 className="mb-4">About</h1>
                    <p>With Kaapse Dictionary, we brought all the different "slang" words that are used in your everyday language to converse amongst each other, making it easier for the locals of South Africa and international visitors to understand what it takes to tell a good story.</p>
                    <p>Many people are intrigued as to what these different words/statements actually means and what are the roots of each and every one of them, with this website we have made it easier for you to access every "local/slang" word.</p>
                    <p>For those who are not aware of these words and how to use them in sentences this website will give you a better idea on how to apply them accordingly.</p>
                </div>
                <div className="text-center">
                    <h4 className="mb-4">Blame Game</h4>
                        <p>Blame <a href="https://www.instagram.com/ighsaanlevy/">Ighsaan Levy</a> for any functionality that doesnt work on the site</p>
                        <p>Blame <a href="https://www.instagram.com/fawwaaz.i/">Fawwaaz Isaacs</a> for any design issues</p>
                        <p>Blame <a href="https://www.instagram.com/uthmaan.w/">Uthmaan Warner</a> for any incorrect definitions</p>
                        <p>Blame <a href="https://www.instagram.com/waseem_manuel/">Waseem Manuel</a> for any offensive examples</p>
                    </div>
                </div>
        )
    }
}