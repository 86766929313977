import React, { Component } from 'react';
import PartOfSpeech from '../util/PartsOfSpeech';

export default class WordForm extends Component {
    constructor(props) {
        super();
    }

    showResult = (word) => {
        this.props.showResult(word);
    }

    render = () => {
        return (
            <div>
                <p className="Word-pos">{PartOfSpeech[this.props.data.partOfSpeech]}</p>
                <p className="Word-description">{this.props.data.definition}</p>
                <p className="Word-example">"{this.props.data.example}"</p>

                {this.props.data.synonyms ?
                <p className="Word-synonyms"><b>Synonyms:</b> {this.props.data.synonyms.map((data, index) => {
                    var x = "/word/"+data;
                    return <a key={index} href={x}>{data} </a>
                })}</p> : "" }

                {this.props.data.englishTranslation ?
                <p className="Word-english"><b>English Translation:</b> {this.props.data.englishTranslation.join(", ")}</p> : "" }
                <hr width="20%"/>
            </div>
        );
    }
}