import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

import WordForm from './WordForm';
import Share from './Share';

export default class Word extends Component {
    constructor(props) {
        super();
    }

    render = () => {
        var name = this.props.data.name[0];
        var title = name + " | Kaapse Dictionary";

        return (
            <div className="Word">
                <h1>{this.props.data.name.join(' / ')}</h1>
                {this.props.data.explanation.map((data, index) => {
                    return <WordForm key={index} showResult={this.props.showResult} data={data}/>
                })}   
                <Share name={name}/>


                <MetaTags>
                    <title>{title}</title>
                    <meta id="meta-title" property="og:title" content={title} />
                </MetaTags>
            </div>
        );
    }
}