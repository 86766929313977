import React, { Component } from 'react';
import './Submit.css'

export default class Submit extends Component {

    render = () => {
        return (
            <div className="Submit">
                <h1>We are still busy finishing off the submit functionality.</h1>
                <h4>Please DM @Kaapsedictionary with any word submisions in the interim</h4>
            </div>
        )
    }
}