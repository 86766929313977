import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import Searchbar from './Searchbar';
import Content from './Content';
import NoMatch from './NoMatch';

import './Search.css';

const reg = new RegExp(/^[a-z]+$/, 'i');

export default class App extends Component {
  constructor(props) {
      super();

      this.state = {
          result: null,
          apiCalled: false,
          isResult: false,
          noMatch: false
      }
  }

  componentDidMount = () => {
    if(this.props.match.params.id) {
      this.showResult(this.props.match.params.id);
    }
    this.notify();
  }

  showResult = async (word) => {
    if(!reg.test(word) || word.length === 0) return;
    const body = await this.callApi(`/api/word/${word}`);

    if(!Object.keys(body).length){
      this.setState({
        noMatch: true
      })
    } else {
      this.setState({
        data: body,
        apiCalled: true,
        isResult: true,
        noMatch: false
      });
    }
  }

  searchWord = async (word) => {
    if(!reg.test(word) || word.length === 0) return;
    const body = await this.callApi(`/api/search/${word}`);
    this.setState({
      data: body,
      apiCalled: true,
      isResult: false,
      noMatch: false
    });
  }

  randomWord = async () => {
    const body = await this.callApi(`/api/random`);
    this.setState({
      data: body,
      apiCalled: true,
      isResult: true,
      noMatch: false
    });
  }

  callApi = async (url) => {
    const response = await fetch(url);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  }

  render = () => {
    return (
      <div className="Search">
        <ToastContainer />
        <div className="logo-container" onClick={this.randomWord}>
          <img src="/logo.svg" className="logo" alt="logo" />
        </div>
        <Searchbar searchWord={this.searchWord}/>
        {this.state.apiCalled ? <Content data={this.state.data} isResult={this.state.isResult} showResult={this.showResult}/> : "" }
        {this.state.noMatch ? <NoMatch /> : "" }
      </div>
    );
  }

  //Some toast
  notify = () => {
    toast("Click on the logo to get a random search result!",{
      position: toast.POSITION.TOP_RIGHT,
      className: 'Notification',
      autoClose: false,
      draggablePercent: 30
    });
  }
}
