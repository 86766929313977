import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import Search from './search/Search';
import Submit from './submit/Submit';
import About from './about/About';



ReactDOM.render(
    <div>
        <Router>
            <div className="nav"> 
                <Link to="/">Search</Link>
                <Link to="/about">About</Link>
                <Link to="/submit"> Submit </Link>
            </div>

            <Switch>
                <Route path="/submit" component={Submit} />
                <Route exact path="/" component={Search} />
                <Route exact path="/about" component={About} />
                <Route path="/word/:id" component={Search} />
                <Route component={Search} />
            </Switch>
        </Router>
    </div>
    , document.getElementById('root'));