import React, { Component } from 'react';

export default class Searchbar extends Component {
    constructor(props) {
        super();
        this.state = {
            typingTimeout: 0
        }
    }

    handleChange = (event) => {
        const self = this;
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }
        var value = event.target.value;

        self.setState({
            typingTimeout: setTimeout(function () {
                self.props.searchWord(value);
            }, 200)
        });
    }

    render = () => {
        return (
            <div>
                <input 
                    id="SearchBar"
                    type="text" 
                    className="Search-bar"
                    autoComplete="off" 
                    onChange={this.handleChange}/>
            </div>
        );
    }
}