import React, { Component } from 'react';
import ResultItem from './ResultItem';

export default class Result extends Component {
    constructor(props) {
        super();
    }

    showResult = (id) => {
        this.props.showResult(id);
    }

    render = () => {
        return (
            <div>
                {this.props.data.map((data) => {
                    return <ResultItem  key={data.id} showResult={this.showResult} data={data} />
                })}   
            </div>
        );
    }
}